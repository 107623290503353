<template>
  <div>
    <el-dialog title="重新上传视频"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="选择文件">
              <div class="addFile"
                   v-if="fileList.length == 0"
                   @click="upload">
                <i class="el-icon-plus"></i>
              </div>
              <div v-else>
                <el-tag closable
                        v-for="(item,index) in fileList"
                        :key="index"
                        @close="clearFile"
                        type="priamry">
                  {{item.name}}
                </el-tag>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="文件名称">
              <el-input clearable
                        v-model="form.filename"
                        placeholder="请输入文件名称" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>


export default {
  data () {
    return {
      form: {},
      text: '上传',
      fileList: [],
      dialogVisible: false,
    }
  },

  methods: {
    upload () {
      this.fileList = []
      this.$get_file({ fileType: 'video/*' }).then(res => {
        this.fileList.push(res)
        this.form.filename = res.name.substring(0, res.name.indexOf("."))
      })
    },
    handleClose () {
      this.fileList = []
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    clearFile () {
      this.fileList = []
    },
    createSource () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        if (!this.fileList.length) {
          return this.$message.warning('请选择视频')
        }

        let formData = new FormData();
        formData.append('video', this.fileList[0])
        let form = JSON.parse(JSON.stringify(this.form))

        for (const key in form) {
          formData.append(key, form[key])
        }

        formData.append('sign', this.$getMd5Form(form))

        this.$http({
          url: '/api/v1/resource/again',
          Headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '上传资源成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}
.addFile {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>